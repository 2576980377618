import { View, Text, Image, StyleSheet, TouchableOpacity, Linking, ScrollView, ActivityIndicator, Modal } from 'react-native'
import React, { useContext, useEffect, useState, } from 'react'
import { Button } from 'react-native-paper'
import { SelectList } from 'react-native-dropdown-select-list'
import TextInput from '../components/TextInput'
import { completeTripStep } from '../api/Apis'
import { AuthContext } from '../core/AuthContext'
import { launchImageLibraryAsync, MediaTypeOptions } from 'expo-image-picker'
import * as Resizer from '../helpers/resize'
import ImageViewer from 'react-native-image-zoom-viewer'

const TaskDetail = ({ route, navigation }) => {
    const [selectedReport, setSelectedReport] = useState("")
    const [currentTaskId, setCurrentTaskId] = useState('')
    const [explain, setExplain] = useState('')
    const [reportDdData, setReportDdData] = useState(null)
    const [currentTask, setCurrentTask] = useState(null)
    const [isPickup, setIsPickup] = useState(false)
    const [reset, setReset] = useState(0)
    const [isCompleted, setIsCompleted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [pickupInfo, setPickupInfo] = useState(false)
    const { token, reportData } = useContext(AuthContext)
    const [visible, setIsVisible] = useState(false);

    useEffect(() => {
        setCurrentTask(route.params.currentTask)
        setIsPickup(checkIsPickUp())
        setCurrentTaskId(route.params.taskId)

        navigation.setOptions({
            headerLeft: () => (
                <Button onPress={() => navigation.goBack()}>
                    <Image source={require('../assets/vc_back.png')} style={{ height: 24, width: 24 }} />
                </Button>
            ),
            headerRight: () => (
                <Text style={{ marginRight: 24, color: '#FFF' }}>{route.params.currentTask.TripStepId}</Text>
            ),
            headerStyle: { backgroundColor: '#253f67', borderBottomWidth: 0 },
            headerTitleStyle: { color: '#FFF' },
        })
        resetReportData()
        if (currentTaskId && route.params.taskId != currentTaskId) {
            setReset(reset + 1)
            setExplain('')
        }
    }, [route.params.taskId])

    useEffect(() => {
        if (isCompleted) {
            setIsCompleted(false)
            setIsLoading(false)
            navigation.navigate("Dashboard", { isRefresh: true })
        }
    }, [isCompleted])

    const resetReportData = () => {
        if(reportData)
        {
            const transferType = checkIsPickUp() ? 1 : 2
            let newArray = reportData
                .filter(x => x.TransferType == transferType)
                .map((item) => {
                    return { key: item.PkReportType, value: item.Title }
                })
            setReportDdData(newArray)
        }
    }

    const checkIsPickUp = () => {
        return route.params.currentTask.Status == 'Pickup'
    }

    const SubmitForm = async () => {
        const result = await launchImageLibraryAsync({
            mediaTypes: MediaTypeOptions.Images,
            quality: 1,
        })

        if (result && !result.canceled && result.assets) {
            setIsLoading(true)
            const uri = result.assets[0].uri
            const newImage = Resizer(uri, 800, 600)
            const reqResult = await completeTripStep(token, newImage, currentTask.TripStepId, explain, selectedReport)
            if (reqResult && reqResult.Status) {
                setIsCompleted(true)
                return
            }
        }

    }

    if (!currentTask) {
        return ''
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size={'large'} />
            </View>
        )
    }

    return (
        <ScrollView
            style={{ width: '100%' }}
            contentContainerStyle={{ flexGrow: 1 }}>
            <View style={styles.orderBar}>
                <View style={styles.orderTime}>
                    <View><Image source={require('../assets/vc_time_w.png')} style={{ height: 24, width: 24 }} /></View>
                    <Text style={[styles.fontWhite, { fontWeight: 'normal' }]}>{isPickup ? currentTask.Pickup.PickupTime : currentTask.Delivery.DeliveryTime}</Text>
                </View>
                <View style={styles.orderTruck}>
                    <Image source={require('../assets/ic_truck.png')} style={styles.truckImage} />
                </View>
                <View style={styles.orderLine}>
                    <View style={{ alignItems: 'self-end' }}><Image source={require('../assets/vc_qr_code_w.png')} style={{ height: 24, width: 24, textAlign: 'right' }} /></View>
                    <Text style={[styles.orderText, styles.fontWhite]}>{currentTask.OrderNo}</Text>
                </View>
            </View>
            {isPickup ? (
                <View style={styles.orderRow}>
                    <View style={styles.orderRowInner}>
                        <View style={styles.deliveryContainer}>
                            <Text style={styles.orderRowLabel}>Restaurant</Text>
                            <Text style={styles.orderRowText}>{currentTask.Pickup.Restaurant}</Text>
                        </View>
                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.callButton} onPress={() => { Linking.openURL(`tel:${currentTask.Pickup.Phone}`) }}>
                                <Image source={require('../assets/vc_phone.png')} style={{ width: 24, height: 24 }} />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            ) : (
                <View style={styles.orderRow}>
                    <View style={styles.orderRowInner}>
                        <View style={styles.deliveryContainer}>
                            <Text style={styles.orderRowLabel}>Deliver To</Text>
                            <Text style={styles.orderRowText}>{currentTask.Delivery.Recipent}</Text>
                        </View>
                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.callButton} onPress={() => { Linking.openURL(`tel:${currentTask.Delivery.Phone}`) }}>
                                <Image source={require('../assets/vc_phone.png')} style={{ width: 24, height: 24 }} />
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.chatButton, styles.secondButton]} onPress={() => { Linking.openURL(`sms:${currentTask.Delivery.Phone}`) }}>
                                <Image source={require('../assets/ic-chat_icon.png')} style={{ width: 24, height: 24 }} />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.deliveryContainer}>
                            <Text style={styles.orderRowLabel}>Upon Delivery Ask For</Text>
                            <Text style={styles.orderRowText}>{currentTask.Delivery.DeliveryAskFor}</Text>
                        </View>
                    </View>
                </View>
            )}
            <View style={styles.orderRow}>
                <View style={styles.orderRowInner}>
                    <View style={styles.addressContainer}>
                        <Text style={styles.orderRowLabel}>Address</Text>
                        <Text style={styles.orderRowText}>{currentTask.Delivery.Address} {currentTask.Delivery.City} {currentTask.Delivery.State}</Text>
                    </View>
                    <View style={styles.singleButtonContainer}>
                        <TouchableOpacity style={styles.dirButton} onPress={() => Linking.openURL(`https://www.google.com/maps/search/?api=1&query=${currentTask.Status == 'Pickup' ? currentTask.Pickup.Lat + ',' + currentTask.Pickup.Long : currentTask.Delivery.Lat + ',' + currentTask.Delivery.Long}`)}>
                            <Image source={require('../assets/vc_navigation.png')} style={{ width: 24, height: 24 }} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View style={styles.orderRow}>
                <View style={styles.orderRowInner2}>
                    <Text style={styles.orderRowLabel}>Notes</Text>
                    <Text style={styles.orderRowText}>{isPickup ? currentTask.Pickup.Notes : currentTask.Delivery.Notes}</Text>
                </View>
            </View>
            {
                !isPickup ? (
                    <View style={styles.orderRow}>
                        <View style={styles.orderRowInner}>
                            <TouchableOpacity onPress={() => pickupInfo ? setPickupInfo(false) : setPickupInfo(true)} style={{ padding: 0, margin: 0, display: 'block', width: '100%' }}>
                                <View>
                                    <View style={styles.deliveryContainer}>
                                        <Text style={styles.orderRowLabel}>Pick Up Info</Text>
                                    </View>
                                    <View style={[styles.singleButtonContainer, { top: 0 }]}>
                                        <Image source={require(pickupInfo ? '../assets/vc_arrow_up.png' : '../assets/vc_arrow_down.png')} style={{ width: 24, height: 24 }} />
                                    </View>
                                </View>
                            </TouchableOpacity>
                            <View style={[styles.addressContainer, !pickupInfo && styles.viewPassive]}>
                                <Text style={styles.orderRowText}>Pickup Time: {currentTask.Pickup.PickupTime}</Text>
                                <Text style={styles.orderRowText}>Address: {currentTask.Pickup.Address}</Text>
                                <Text style={styles.orderRowText}>Restaurant: {currentTask.Pickup.Restaurant}</Text>
                                <Text style={styles.orderRowText}>Note: {currentTask.Pickup.Notes}</Text>
                                <TouchableOpacity onPress={() => setIsVisible(true)}>
                                    <Image source={currentTask.Pickup.Photo} style={{ width: '100%', height: 60 }} />
                                </TouchableOpacity>
                                <Modal visible={visible} transparent={true} animationType='fade'>
                                    <ImageViewer
                                        imageUrls={[{ url: currentTask.Pickup.Photo }]}
                                    />
                                    <TouchableOpacity onPress={() => setIsVisible(false)} style={styles.closeButton}>
                                        <Text style={{ color: '#000', fontWeight: 'bold', fontSize: 18, lineHeight: 40 }}>X</Text>
                                    </TouchableOpacity>
                                </Modal>
                            </View>
                        </View>
                    </View>
                ) : (<></>)
            }
            <View style={styles.orderRow}>
                <View style={styles.orderRowInner}>
                    <Text style={styles.orderRowLabel}>How did it go?</Text>
                    <TextInput
                        label='You can type here'
                        returnKeyType="done"
                        onChangeText={(text) => setExplain(text)}
                        value={explain}
                        autoCapitalize="none"
                        autoCompleteType="text"
                        textContentType="text"
                        keyboardType="text" />
                </View>
            </View>
            <View style={styles.orderRowReport}>
                <SelectList
                    setSelected={(val) => setSelectedReport(val)}
                    value={selectedReport}
                    data={reportDdData}
                    save='key'
                    key={reset}
                    search={false}
                    placeholder='Report a problem'
                    defaultOption={{ key: '', value: 'Report a problem' }}
                    dropdownTextStyles={{ color: '#FFF' }}
                    boxStyles={{ border: 0 }}
                    dropdownStyles={{ border: 0 }}
                    inputStyles={{ color: '#FFF' }}
                    arrowicon={<Image
                        source={require('../assets/arrow_right_w.png')}
                        style={{
                            width: 14,
                            height: 14
                        }}
                    />}
                />
            </View>

            <View style={styles.checkButtonContainer}>
                <TouchableOpacity style={styles.checkButton} onPress={SubmitForm}>
                    <Image source={require('../assets/vc_done.png')} style={{ width: 30, height: 30 }} />
                </TouchableOpacity>
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    orderBar: {
        height: 80,
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#253f67',
        padding: 10
    },
    orderTruck: {
        height: 45,
        width: '33%',
    },
    orderTime: {
        width: '33%',
        height: 45,
        alignItems: 'left',
    },
    orderLine: {
        width: '33%',
        height: 45,
        alignItems: 'right',
        textAlign: 'right'
    },
    orderText: {
        textAlign: 'right'
    },
    fontWhite: {
        color: '#FFF',
        fontSize: 18,
        lineHeight: 30
    },
    truckImage: {
        width: 50,
        height: 45,
        alignSelf: 'center',
    },
    orderRow: {
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 10,
        backgroundColor: '#FFF',
        border: '1px solid #EEE',
        transition: 'linear all 0.4s',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.25,
        shadowRadius: 2.01,
    },
    orderRowReport: {
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 10,
        backgroundColor: '#ff3d23',
        border: '1px solid #ff3d23',
        transition: 'linear all 0.4s',
        overflow: 'hidden',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.25,
        shadowRadius: 2.01,
    },
    orderRowInner: {
        padding: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    orderRowInner2: {
        padding: 20,
    },
    orderRowLabel: {
        fontSize: 18,
        color: '#888',
        lineHeight: 30,
        fontWeight: 600
    },
    orderRowText: {
        fontSize: 16,
        color: '#000',
        marginRight: 10,
    },
    callButton: {
        backgroundColor: '#00657c',
        borderRadius: 50,
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 4.84,
    },
    chatButton: {
        backgroundColor: '#462fb1',
        borderRadius: 50,
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 4.84,
    },
    dirButton: {
        backgroundColor: '#3f4156',
        borderRadius: 50,
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 4.84,
        elevation: 5
    },
    secondButton: {
        marginLeft: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        position: 'absolute',
        right: 30,
        top: 30,
    },
    singleButtonContainer: {
        flexDirection: 'row',
        position: 'absolute',
        right: 20,
        top: 30,
        minWidth: '10%',
        justifyContent: 'flex-end'
    },
    checkButtonContainer: {
        marginTop: 20,
        alignItems: 'flex-end',
        paddingRight: 30,
        paddingBottom: 30,
    },
    checkButton: {
        backgroundColor: '#4a34b0',
        borderRadius: 50,
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 5.84,
        elevation: 5
    },
    reportProblemIcon: {
        transform: [{ rotate: '90deg' }]
    },
    deliveryContainer: {
        width: '60%',
        paddingRight: 10
    },
    buttonContainer: {
        flexDirection: 'row',
        width: '40%',
        justifyContent: 'flex-end'
    },
    addressContainer: {
        width: '80%', // Adres bilgisi için daha fazla alan ayırdık
        paddingRight: 10
    },
    viewPassive: {
        display: 'none'
    },
    closeButton: {
        position: 'fixed', 
        width: 40, 
        height: 40, 
        top: 27, 
        left: 20,
        backgroundColor: '#EEE',
        borderRadius: '18px',
        alignItems: 'center',
    }
})

export default TaskDetail