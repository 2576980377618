import React, { useContext } from 'react'
import { DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer'
import { DrawerActions } from '@react-navigation/native'
import { Image, StyleSheet, View, Text } from 'react-native'
import { AuthContext } from '../core/AuthContext'

const CustomDrawer = (props) => {
    const { userInfo, logout } = useContext(AuthContext)

    const onLogoutPressed = () => {
        logout()
        props.navigation.dispatch(DrawerActions.closeDrawer())
    }

    return (
        <View style={{ flex: 1 }}>
            <DrawerContentScrollView {...props} contentContainerStyle={{ paddingTop: 0 }}>
                <View style={styles.drawerContent}>
                    <Image source={require('../assets/here_logo_org.png')} style={styles.hereAppLogo} />
                    <Text style={styles.userItem}>{userInfo == null ? 'Loading..' : `${userInfo.Name} ${userInfo.Surname}`}</Text>
                </View>
                <DrawerItemList {...props} />
                <DrawerItem
                    style={{ borderTopWidth: 1, borderTopColor: '#d5d5d5', borderRadius: 0 }}
                    icon={() => (<Image source={require('../assets/vc_logout.png')}
                        style={{ height: 24, width: 24 }} />)}
                    label="Logout"
                    labelStyle={styles.drawerItem}
                    onPress={onLogoutPressed} />
            </DrawerContentScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    drawerContent: {
        backgroundColor: '#253f67', 
        height: 250, 
        marginBottom: 15
    },
    hereAppLogo: {
        height: 110, 
        width: 110, 
        alignSelf: 'center', 
        marginTop: 60 
    },
    drawerItem: {
        marginLeft: -15,
        color: '#000',
        fontSize: 16
    },
    userItem: {
        fontSize: 20,
        color: 'white',
        textAlign: 'center',
        marginTop: 5
    }
})

export default CustomDrawer