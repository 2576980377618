import React, { useContext, useState } from 'react'
import { TouchableOpacity, StyleSheet, View } from 'react-native'
import { Checkbox, Text } from 'react-native-paper'
import Background from '../components/Background'
import Logo from '../components/Logo'
import Button from '../components/Button'
import TextInput from '../components/TextInput'
import { userNameValidator } from '../helpers/userNameValidator'
import { passwordValidator } from '../helpers/passwordValidator'
import { AuthContext } from '../core/AuthContext'

const LoginScreen = ({ navigation }) => {
  const [userName, setUserName] = useState({ value: '', error: '' })
  const [password, setPassword] = useState({ value: '', error: '' })
  const [checked, setChecked] = useState('')
  const [agreementChecked, setAgreementChecked] = useState(true)
  const { login, setIsLoading } = useContext(AuthContext)

  const onLoginPressed = async () => {
    const userNameError = userNameValidator(userName.value)
    const passwordError = passwordValidator(password.value)
    if (userNameError || passwordError) {
      setUserName({ ...userName, error: userNameError })
      setPassword({ ...password, error: passwordError })
      return
    }

    if (!agreementChecked) {
      setPassword({ ...agreementChecked, error: 'Please agree to privacy policy and terms of service' })
      return
    }

    var loginResponse = await login(userName.value, password.value, checked.value)    
    if (!loginResponse || !loginResponse.Token) {
      setPassword({ ...password, error: loginResponse })
      console.log(loginResponse)
      return
    }
  }


  return (
    <Background>
      {/* <BackButton goBack={navigation.goBack} /> */}
      <View style={styles.logoZone}>
        <Logo />
      </View>
      <View style={styles.formZone}>
        <TextInput
          label="Username"
          returnKeyType="next"
          value={userName.value}
          onChangeText={(text) => setUserName({ value: text, error: '' })}
          error={!!userName.error}
          errorText={userName.error}
          autoCapitalize="none"
          autoCompleteType="userName"
          textContentType="userName"
          keyboardType="userName"
        />
        <TextInput
          label="Password"
          returnKeyType="done"
          value={password.value}
          onChangeText={(text) => setPassword({ value: text, error: '' })}
          error={!!password.error}
          errorText={password.error}
          secureTextEntry
        />
        <View style={styles.row}>
          <Checkbox
            status={checked ? 'checked' : 'unchecked'}
            onPress={() => {
              setChecked(!checked)
            }
            }
          />
          <Text style={styles.bold}> Remember my credantials </Text>
        </View>
        <View style={styles.row}>
          <Checkbox
            status={agreementChecked ? 'checked' : 'unchecked'}
            onPress={() => {
              setAgreementChecked(!agreementChecked)
            }
            }
          />
          <Text style={styles.bold}> I agree to </Text>
          <TouchableOpacity onPress={() => navigation.navigate('RegisterScreen')}>
            <Text style={styles.link}>privacy policy</Text>
          </TouchableOpacity>
          <Text style={styles.bold}> and </Text>
          <TouchableOpacity onPress={() => navigation.navigate('RegisterScreen')}>
            <Text style={styles.link}>terms of service</Text>
          </TouchableOpacity>
        </View>
        <Button style={styles.signIn} mode="contained" onPress={onLoginPressed}>
          SIGN IN
        </Button>
      </View>
    </Background>
  )
}

const styles = StyleSheet.create({
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row'
  },
  bold: {
    fontWeight: 600,
    lineHeight: 36
  },
  link: {
    fontWeight: 'bold',
    color: '#2bdf35',
    textDecorationLine: 'underline',
    lineHeight: 36
  },
  signIn: {
    maxWidth: '45%',
    alignSelf: 'center',
    marginTop: 40,
    borderRadius: 10,
    backgroundColor: '#253f67',
  },
  formZone: {
    padding: 20,
    width: '100%',
    backgroundColor: '#f6f7fc',
    flex: 1
  },
  logoZone: {
    backgroundColor: '#253f67',
    width: '100%',
    alignItems: 'center',
    minHeight: 200
  }
})

export default LoginScreen