import React from "react";
import { View, ActivityIndicator } from "react-native";

const Loader = () => {
  return (
    <View style={{  position: 'absolute',
      top: '0',
      bottom: '0',
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f6f7fc',
      position: 'fixed',
      zIndex: 100 }}>
      <ActivityIndicator size={'large'} />
    </View>
  );
};

export default Loader;