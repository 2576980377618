import React, { useContext, useEffect } from 'react'
import { Image } from 'react-native'
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native'
import LoginScreen from '../screens/LoginScreen'
import Dashboard from '../screens/Dashboard'
import OrderHistory from '../screens/OrderHistory'
import TaskDetail from '../screens/TaskDetail'
import DriverVesting from '../screens/DriverVesting'
import { AuthContext } from './AuthContext'
import CustomDrawer from './CustomDrawer'
import { Button } from 'react-native-paper'
import useLoader from '../core/UseLoader'

const Drawer = createDrawerNavigator()
const AppNav = () => {
const { isLogin, checkLogin, getReportData, isLoading } = useContext(AuthContext)
const [loader, showLoader, hideLoader] = useLoader();

  useEffect(() => {
    getPreload()
  }, [])

  useEffect(() => {
    if (isLoading) {
      showLoader()
    }
    else {
      hideLoader()
    }
  }, [isLoading])

  const getPreload = async () => {
    const token = await checkLogin()
    getReportData(token)
  }

  return (
    <>
      {loader}
      <NavigationContainer>
        <Drawer.Navigator
          drawerContent={props => <CustomDrawer {...props} />}
          screenOptions={{
            headerShown: false,
            drawerActiveBackgroundColor: '#8200d624',
            drawerActiveTintColor: '#000',
            drawerInactiveTintColor: '#000',
            drawerLabelStyle: {
              marginLeft: -15,
              fontSize: 16
            }
          }}>
          {!isLogin ? (
            <>
              <Drawer.Screen
                name="LoginScreen"
                component={LoginScreen}
                options={{
                  title: "Title",
                  drawerItemStyle: {
                    display: "none",
                  }
                }} />
            </>
          ) : (
            <>
              <Drawer.Screen
                name="Dashboard"
                component={Dashboard}
                options={{
                  headerShown: true,
                  title: "Tasks",
                  drawerLabel: "My Task",
                  headerRight: () => (<Button />),
                  drawerIcon: () => (<Image source={require('../assets/vc_local_shipping.png')} style={{ height: 24, width: 24 }} />)
                }} />
              <Drawer.Screen
                name="TaskDetail"
                component={TaskDetail}
                options={{
                  headerShown: true,
                  title: "Tasks",
                  headerLeft: () => (<Button />),
                  drawerItemStyle: {
                    display: "none",
                  }
                }} />
              <Drawer.Screen
                name="OrderHistory"
                component={OrderHistory}
                options={{
                  headerShown: true,
                  title: "Order History",
                  drawerIcon: () => (<Image source={require('../assets/vc_time.png')} style={{ height: 24, width: 24 }} />)
                }} />
              <Drawer.Screen
                name="DriverVesting"
                component={DriverVesting}
                options={{
                  headerShown: true,
                  title: "Earnings",
                  drawerLabel: "Earnings",
                  headerLeft: () => (<Button />),
                  drawerIcon: () => (<Image source={require('../assets/vc_history_multi.png')} style={{ height: 24, width: 24 }} />)
                }} />
            </>
          )}
        </Drawer.Navigator>
      </NavigationContainer>
    </>
  )
}

export default AppNav