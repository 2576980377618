import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../core/AuthContext'
import { getTask } from '../api/Apis'
import { View, Text, Image, StyleSheet, TouchableOpacity, ActivityIndicator, Linking } from 'react-native'
import { Button } from 'react-native-paper'

const Dashboard = ({ route, navigation }) => {

  // -- Definitions
  const { logout, token } = useContext(AuthContext)
  const [isLoadingPartial, setIsLoadingPartial] = useState(false)
  const [taskList, setTaskList] = useState([])
  const [selectedTab, setSelectedTab] = useState('today')  // Yeni sekme durumu
  // -- End Definitions

  // -- Functions
  const fetchTask = async () => {
    if (token != '') {
      setIsLoadingPartial(true)
      const taskResponse = await getTask(token, selectedTab == 'tomorrow')
      if (taskResponse.hasOwnProperty("Message")) {
        logout()
        setIsLoadingPartial(false)
        return
      }
      setIsLoadingPartial(false)
      setTaskList(taskResponse)
    }
  }
  // -- End Functions

  useEffect(() => {
    fetchTask()
  }, [navigation])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button onPress={() => fetchTask()}>
          <Image source={require('../assets/vc_refresh.png')} style={{ height: 24, width: 24 }} />
        </Button>
      ),
    })
  }, [navigation, selectedTab])

  useEffect(() => {
    if (route.params && route.params.isRefresh) {
      route.params.isRefresh = false
      fetchTask()
    }
  }, [route.params?.isRefresh])

  useEffect(() => {
    fetchTask()    
  }, [selectedTab])

  let currentTask
  if (taskList.length > 0) {
    currentTask = taskList[0]
  }

  return (
    <>
      <View style={styles.tabs}>
        <TouchableOpacity
          style={[styles.tab, selectedTab === 'today' && styles.activeTab]}
          onPress={() => setSelectedTab('today')}>
          <Text style={styles.tabText}>Today</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tab, selectedTab === 'tomorrow' && styles.activeTab]}
          onPress={() => setSelectedTab('tomorrow')}>
          <Text style={styles.tabText}>Tomorrow</Text>
        </TouchableOpacity>
      </View>
      {isLoadingPartial ? (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
            <ActivityIndicator size={'large'} />
        </View>
        ) : 
        taskList.length < 1 ? (
          <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
            <Text style={{ textAlign: 'center', fontSize: 20 }}>
              <Image source={require('../assets/vc_empty.png')} style={{ height: 64, width: 64 }} /><br />No task at the moment
            </Text>
          </View>
        ) : (
          <>
          <View style={styles.orderRow}>
            <TouchableOpacity onPress={() => { if(selectedTab == 'today') { navigation.navigate("TaskDetail", { currentTask: currentTask, taskId: Math.floor(Math.random() * 1000) }) }}}>
              <View style={styles.orderRowInner}>
                <View style={styles.truck}>
                  <Image source={require('../assets/ic_truck.png')} style={styles.truckImage} />
                  <Text style={styles.truckText}>{currentTask.Status}</Text>
                </View>
                <View style={styles.orderDetail}>
                  <View style={styles.orderLine}>
                    <View style={{ width: '10%' }}><Image source={require('../assets/vc_qr_code.png')} style={{ height: 24, width: 24 }} /></View>
                    <Text style={styles.orderText}>{currentTask.OrderNo}</Text>
                    <Text style={styles.orderRight}>{currentTask.Value}</Text>
                  </View>
                  <View style={styles.orderLine}>
                    <View style={{ width: '10%' }}><Image source={require('../assets/vc_time.png')} style={{ height: 24, width: 24 }} /></View>
                    <Text style={[styles.orderText, { fontWeight: 'normal' }]}>{currentTask.Status == 'Pickup' ? currentTask.Pickup.PickupTime : currentTask.Delivery.DeliveryTime}</Text>
                  </View>
                  <View style={styles.orderLine}>
                    <Text style={[styles.fontBold, styles.fontRow]}>Res: </Text><Text style={styles.fontRow}>{currentTask.Pickup.Restaurant}</Text>
                  </View>
                  <View style={styles.orderLine}>
                    <Text style={[styles.fontBold, styles.fontRow]}>Addr: </Text><Text>{currentTask.Status == 'Pickup' ? `${currentTask.Pickup.Address} ${currentTask.Pickup.City} ${currentTask.Pickup.State}` : `${currentTask.Delivery.Address} ${currentTask.Delivery.City} ${currentTask.Delivery.State}`}</Text>
                  </View>
                  <View style={styles.orderLine}>
                    <Text style={[styles.fontBold, styles.fontRow]}>Note: </Text><Text>{currentTask.Status == 'Pickup' ? currentTask.Pickup.Notes : currentTask.Delivery.Notes}</Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
            { selectedTab == 'today' ?
              (
                <View style={[styles.orderRowInner, { width: "100%" }]}>
                <View style={styles.rowButton}>
                  <Button style={styles.callButton} onPress={() => { Linking.openURL(`tel:${currentTask.Status == 'Pickup' ? currentTask.Pickup.Phone : currentTask.Delivery.Phone}`) }}><Image source={require('../assets/vc_phone.png')} style={{ height: 24, width: 24 }} /></Button>
                </View>
                <View style={styles.rowButton}>
                  <Button style={styles.navigateButton}
                    onPress={() => Linking.openURL(`https://www.google.com/maps/search/?api=1&query=${currentTask.Status == 'Pickup' ? currentTask.Pickup.Lat + ',' + currentTask.Pickup.Long : currentTask.Delivery.Lat + ',' + currentTask.Delivery.Long}`)}><Image source={require('../assets/vc_navigation.png')} style={{ height: 24, width: 24 }} /></Button>
                </View>
              </View>
              ) : (<></>)
            }
          </View>          
          { 
            taskList.map((item, indexTask) => {
              return (
                indexTask == 0 ? ('') : (
                  <View key={indexTask} style={styles.orderRow}>
                    <View style={styles.orderRowInner}>
                      <View style={styles.dropeoff}><Text style={styles.dropeoffText}>{item.Status == 'Pickup' ? 'Pickup' : 'Drop Off'}</Text></View>
                      <View style={styles.dropeoffOrder}><Text style={[styles.orderText, styles.dropeoffOrderText]}>{item.OrderNo}</Text></View>
                      <View style={styles.dropeoffDate}><Text style={[styles.orderText, styles.dropeoffOrderText, { fontWeight: 'normal' }]}>{item.Status == 'Pickup' ? item.Pickup.PickupTime : item.Delivery.DeliveryTime}</Text></View>
                    </View>
                  </View>
                )
              )
            })
          }
          </>
        )
      }
    </>
  )
}

const styles = StyleSheet.create({
  tabs: {
    flexDirection: 'row',
    backgroundColor: '#f8f8f8',
    display: 'flex'
  },
  tab: {
    flex: 1,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#007AFF',
  },
  tabText: {
    fontSize: 16,
  },
  orderRow: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#FFF',
    border: '1px solid #EEE',
    transition: 'linear all 0.4s',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.01,
  },
  orderRowInner: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  truck: {
    backgroundColor: '#09a431',
    width: '30%',
    alignContent: 'center'
  },
  truckImage: {
    width: 50,
    height: 45,
    alignSelf: 'center',
    marginTop: 30
  },
  truckText: {
    color: '#FFF',
    fontSize: 20,
    alignSelf: 'center'
  },
  orderDetail: {
    padding: 10,
    width: '70%'
  },
  orderLine: {
    flexDirection: 'row',
    paddingTop: 5
  },
  orderText: {
    fontSize: 18,
    marginLeft: 12,
    fontWeight: '600',
    width: '70%'
  },
  orderRight: {
    fontSize: 18,
    marginLeft: 12,
    fontWeight: '600',
    width: 25,
    height: 25,
    backgroundColor: '#d52a2f',
    borderRadius: 13,
    textAlign: 'center',
    color: '#FFF'
  },
  rowButton: {
    width: "50%",
  },
  callButton: {
    backgroundColor: "#00657c",
    borderRadius: 0
  },
  navigateButton: {
    backgroundColor: "#313443",
    borderRadius: 0
  },
  doneButton: {
    backgroundColor: "#4a34b0",
    borderRadius: 0
  },
  dropeoff: {
    backgroundColor: "#71d87c",
    borderRadius: 0,
    width: '20%',
    height: 50,
  },
  dropeoffText: {
    color: 'white',
    fontWeight: '600',
    lineHeight: 50,
    textAlign: 'center',
  },
  dropeoffOrder: {
    width: '40%',
  },
  dropeoffDate: {
    width: '40%',
  },
  dropeoffOrderText: {
    lineHeight: 50,
    textAlign: 'center',
  },
  fontBold: {
    fontWeight: 'bold'
  },
  fontRow: {
    size: 16
  }
})

export default Dashboard