import { View, Text, StyleSheet, Image, FlatList, StatusBar, TouchableOpacity } from 'react-native'
import { Button } from 'react-native-paper'
import React, { useEffect, useState, useContext } from 'react'
import { getDriverVesting } from '../api/Apis'
import { AuthContext } from '../core/AuthContext'

const DriverVesting = ({ route, navigation }) => {
    const { logout, token, setIsLoading } = useContext(AuthContext)
    const [earnings, setEarnings] = useState([])
    const [columns, setColumns] = useState([
        "Week",
        "Delivery",
        "Amount"
    ])

    const fetchTask = async () => {
        if (token != '') {
            setIsLoading(true)
            const earnResponse = await getDriverVesting(token)            
            if (earnResponse.Message) {
                logout()
                setIsLoading(false)
                return
            }
            setIsLoading(false)
            setEarnings(earnResponse.Data)
        }
    }

    useEffect(() => {
        fetchTask();
    }, [])

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <Button onPress={() => navigation.goBack()}>
                    <Image source={require('../assets/vc_back_black.png')} style={{ height: 24, width: 24 }} />
                </Button>
            ),
            headerStyle: { backgroundColor: '#FFF', borderBottomWidth: 0 },
            
        })
    }, [])

    const formatMoney = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      
    const tableHeader = () => (
        <View style={styles.tableHeader}>
            {
                columns.map((column, index) => {
                    {
                        return (
                            <TouchableOpacity
                                key={index}
                                style={styles.columnHeader}
                                onPress={() => sortTable(column)}>
                                <Text style={styles.columnHeaderTxt}>{column}</Text>
                            </TouchableOpacity>
                        )
                    }
                })
            }
        </View>
    )

    return (
        <View style={styles.container}>
            <FlatList
                data={earnings}
                style={{ width: "100%" }}
                keyExtractor={(item, index) => index + ""}
                ListHeaderComponent={tableHeader}
                stickyHeaderIndices={[0]}
                renderItem={({ item, index }) => {
                    return (
                        <View style={{ ...styles.tableRow, backgroundColor: index % 2 == 1 ? "#F0FBFC" : "white" }}>
                            <Text style={styles.columnRowTxt}>{item.CurrentWeek ? 'Current Week' : item.Week}</Text>
                            <Text style={styles.columnRowTxt}>{item.DeliveryCount}</Text>
                            <Text style={[styles.columnRowTxt, styles.columnRowBold]}>{formatMoney.format(item.Amount)}</Text>                            
                        </View>
                    )
                }}
            />
            <StatusBar style="auto" />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: "#253f67",
        height: 40
    },
    tableRow: {
        flexDirection: "row",
        height: 40,
        alignItems: "center",
    },
    columnHeader: {
        width: "33%",
        justifyContent: "center",
        alignItems: "center"
    },
    columnHeaderTxt: {
        color: "white",
        fontWeight: "bold",
    },
    columnRowTxt: {
        width: "33%",
        textAlign: "center",
    },
    columnRowBold: {
        fontWeight: 'bold'
    }
})

export default DriverVesting