export const END_POINT = 'https://mapi.here-app.com'
export const LOGIN_END_POINT = `${END_POINT}/v2/DriverLogin`
export const TASK_END_POINT = `${END_POINT}/v2/Task`
export const TOMORROW_TASK_END_POINT = `${END_POINT}/v2/TomorrowTask`
export const REPORT_TYPE_END_POINT = `${END_POINT}/ReportType`
export const COMPLETE_TRIP_STEP_END_POINT = `${END_POINT}/V2/UploadPhoto`
export const DRIVER_VESTING_END_POINT = `${END_POINT}/v2/DriverVesting`


export function SET_TOKEN(token) {
    sessionStorage.setItem('token', token)
}

export function GET_TOKEN() {
    return sessionStorage.getItem('token')
}