import { View, Text } from 'react-native'
import React from 'react'

const OrderHistory = () => {
    return (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
            <Text style={{ textAlign: 'center', fontSize: 18 }}>
            You don't have any order history</Text>
          </View>
    )
}

export default OrderHistory