import React, { createContext, useState } from 'react'
import { SET_TOKEN, GET_TOKEN } from './GlobalVars'
import { doLogin, getDriverInfo, getReportProblems } from '../api/Apis'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [userInfo, setUserInfo] = useState(null)
    const [reportData, setReportData] = useState(null)
    const [token, setToken] = useState('')

    const login = async (username, password, checked) => {
        setIsLoading(true)
        const loginResponse = await doLogin(username, password)
        if (!loginResponse || !loginResponse.Token) {
            setIsLoading(false)
            return loginResponse
        }
        const loginToken = loginResponse.Token

        const user = await getDriverInfo(loginToken)
        if (!user || !user.Data) {
            setIsLoading(false)
            return user
        }

        if (checked) {
            AsyncStorage.setItem('token', loginResponse.Token)
            AsyncStorage.setItem('expires', loginResponse.ExpiresIn)
        }

        AsyncStorage.setItem('userInfo', JSON.stringify(user.Data))
        setUserInfo(user.Data)
        setIsLogin(true)
        SET_TOKEN(loginResponse.Token)
        setToken(loginResponse.Token)
        setIsLoading(false)
        return loginResponse
    }

    const logout = async (checked) => {
        setIsLoading(true)
        setUserInfo(null)
        setIsLogin(false)
        setToken('')
        SET_TOKEN(null)
        AsyncStorage.setItem('userInfo', null)
        AsyncStorage.setItem('token', null)
        setIsLoading(false)
    }

    const checkLogin = async () => {
        let oldToken = await AsyncStorage.getItem('token')
        const token = GET_TOKEN()
        if (token != "null") {
            oldToken = token
        }
        if (oldToken != "null") {
            SET_TOKEN(oldToken)
        }        
        if (oldToken == "null") {
            setIsLogin(false)
            return false
        }

        if (userInfo == null)
        {
            const currentUser = await getAuthUser()
            if (!currentUser) {
                setIsLogin(false)
                return false
            }
            setUserInfo(currentUser)
        }

        setIsLogin(true)        
        setToken(oldToken)
        return oldToken
    }

    const getAuthUser = async () => {
        const currentUser = await AsyncStorage.getItem('userInfo')
        const userData = JSON.parse(currentUser)
        return userData
    }

    const getReportData = async (tokenParam) => {
        if (tokenParam != '') {
            const response = await getReportProblems(tokenParam)
            setReportData(response)
        }
    }

    return (
        <AuthContext.Provider value={{ isLoading, isLogin, userInfo, token, reportData, setIsLoading, login, logout, checkLogin, getAuthUser, getReportData }}>
            {children}
        </AuthContext.Provider>
    )
}