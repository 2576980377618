import React from 'react'
import { AuthProvider } from './src/core/AuthContext'
import AppNav from './src/core/Appnav'
import 'react-native-gesture-handler'

export default function App() {

  return (
    <AuthProvider>        
          <AppNav />
    </AuthProvider>
  )
}
