import * as constants from '../core/GlobalVars'

export async function doLogin(username, password) {
  const loginRequest = await fetch(constants.LOGIN_END_POINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  })

  const loginResponse = await loginRequest.json()
  return loginResponse
}

export async function getDriverInfo(token) {
  const loginRequest = await fetch(constants.LOGIN_END_POINT, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })

  const userResponse = await loginRequest.json()
  return userResponse
}

export async function getTask(token, isTomorrow) {
  const reqUrl = isTomorrow ? constants.TOMORROW_TASK_END_POINT : constants.TASK_END_POINT
  const webRequest = await fetch(reqUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })

  const response = await webRequest.json()
  return response
}

export async function getReportProblems(token, transferType) {
  const request = await fetch(`${constants.REPORT_TYPE_END_POINT}?TransferType=${transferType}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })

  const response = await request.json()
  return response
}

export async function completeTripStep(token, newImage, tripStepId, driverNote, reportType) {
  const imgRequest = await fetch(newImage)
  const img = await imgRequest.blob()

  const formData = new FormData()
  const file = new File([img], "filename.jpeg")
  formData.append('image', file)
  formData.append('driverNote', driverNote)
  formData.append('id', tripStepId)

  if (reportType) {
    formData.append('reportType', reportType)
  }

  const request = await fetch(constants.COMPLETE_TRIP_STEP_END_POINT, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
  const response = await request.json()
  return response
}

export async function getDriverVesting(token) {
  const request = await fetch(`${constants.DRIVER_VESTING_END_POINT}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  const response = await request.json()
  return response
}